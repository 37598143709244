import React, { Component } from "react";
import image1 from "../media/erroticaarchive_falikoz_felicity_high_0061.jpg";
import { LineChart, BarChart, Bar, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip, ResponsiveContainer } from 'recharts';
const testdata = [
  {name: 'KW01', uv: 400, pv: 450, amt: 450},
  {name: 'KW02', uv: 500, pv: 460, amt: 450},
  {name: 'KW03', uv: 600, pv: 440, amt: 450},
  {name: 'KW04', uv: 700, pv: 450, amt: 450},
  {name: 'KW05', uv: 800, pv: 460, amt: 450},
];

const currentInitValue = 72682.1;
const waterInitValue = 9925659;
const response = [
  {name: "KW01", current: 72682.1, water: 9925659},
  {name: "KW02", current: 72765.7, water: 9998670},
  {name: "KW03", current: 72856.6, water: 10062838},
]


const data = [
  {name: "KW02", current: 72765.7-response[0].current, water: 9998670-response[0].water},
  {name: "KW03", current: 72856.6-response[1].current, water: 10062838-response[1].water},
]

const renderLineChart = (
  <ResponsiveContainer width="80%" height={400}>
    <LineChart data={data}>
      <Line type="monotone" dataKey="current" stroke="#f3e141" />
      {/*<Line type="monotone" dataKey="water" stroke="#5cc9f5" />*/}
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
      <XAxis dataKey="name" />
      <YAxis yAxisId="left" tickCount={10} orientation="left" />
      <YAxis yAxisId="right" tickCount={10} orientation="right"/>
      <Legend />
      <Tooltip />
    </LineChart>
  </ResponsiveContainer>
);

const renderBarChart = (
  <ResponsiveContainer width="80%" height={400}>
    <BarChart data={data}>
      <Bar yAxisId="left" dataKey="current" barSize={30} fill="#f3e141"/>
      <Bar yAxisId="right" dataKey="water" barSize={30} fill="#5cc9f5"/>
      <XAxis dataKey="name" />
      <YAxis yAxisId="left" tickCount={10} orientation="left" />
      <YAxis yAxisId="right" tickCount={10} orientation="right"/>
      <Legend />
      <Tooltip />
    </BarChart>
  </ResponsiveContainer>
);

class special extends Component {
  render() {
    return renderBarChart
  }
}

export default special