import React, { Component } from "react";
import ToggleButton from '@material-ui/lab/ToggleButton';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import './Areas.css'
import Room from "./Rooms";
import axios from "axios";

export default class Areas extends Component {

  constructor(props) {
    super(props);

    this.state = {
      //rooms
      rooms: {
        bathroom2f: new Room('Bathroom', '2F', 'loading...'),
        hallway2f: new Room('Hallway', '2F', 'loading...'),
        bathroom1f: new Room('Bathroom', '1F', 'loading...'),
        hallway1f: new Room('Hallway', '1F', 'loading...'),
        kitchen: new Room('Kitchen', '1F', 'loading...'),
        trash: new Room('Trash', '', 'loading...'),
        cellar: new Room('Cellar', '', 'loading...'),
        grill: new Room('Grill', '', 'loading...'),
      },
      loading: false,
      selected: false,
      striked: false,
      button: {
        disabled: false,
      }
    }
  }

  closeModal = () => {
    document.getElementById("confirm-button").style.display = "none";
  }

  confirmButtonClick = () => {
    // alert('fuck you')
    document.getElementById("confirm-button").style.display = "block";
  };

  handleButtonClick = (event) => {
    this.setState(() => ({
      selected: !this.state.selected,
      button: {
        disabled: true,
      }
    }))

    setTimeout(() => {
      this.setState(() => ({
        selected: false,
        button: {
          disabled: false,
        }
      }))
    }, 1500)

  }

  handleOptOut = (event) => {

    this.handleButtonClick()
    // this.setState({
    //   loading: true,
    // })
    const button = event.target.closest('button');
    const name = event.target.parentElement.closest('td').previousElementSibling.id;
    const userId = event.target.parentElement.closest('td').previousElementSibling.id;
    const room = event.target.parentElement.closest('td').previousElementSibling.previousElementSibling.id;
    const userRoom = {
      id: userId,
      name: name,
      room: room
    }
    button.classList.toggle('button-clicked')
    // console.log(button);
    document.getElementById(room).nextElementSibling.classList.toggle('crossed-out')
    axios
      .post('/strikeOut', userRoom)
      .then((response) => {
        if (room === 'bathroom2f' || room === 'hallway2f') {
          this.setState((prevState) => ({
            rooms: {
              ...prevState.rooms,
              bathroom2f: {
                ...prevState.rooms.bathroom2f,
                person: response.data.result.id,
                previousUsers: [userId],
              },
              hallway2f: {
                ...prevState.rooms.hallway2f,
                person: response.data.result.id,
                previousUsers: [userId],
              }
            }
          }))
          setTimeout(function () {
            document.getElementById('bathroom2f').nextElementSibling.classList.toggle('crossed-out')
            document.getElementById('hallway2f').nextElementSibling.classList.toggle('crossed-out')
          }, 1500)
        } else if (room === 'bathroom1f' || room === 'hallway1f') {
          this.setState((prevState) => ({
            rooms: {
              ...prevState.rooms,
              bathroom1f: {
                ...prevState.rooms.bathroom1f,
                person: response.data.result.id,
                previousUsers: [userId],
              },
              hallway1f: {
                ...prevState.rooms.hallway1f,
                person: response.data.result.id,
                previousUsers: [userId],
              }
            }
          }))
          setTimeout(function () {
            document.getElementById('bathroom1f').nextElementSibling.classList.toggle('crossed-out')
            document.getElementById('hallway1f').nextElementSibling.classList.toggle('crossed-out')
          }, 1500)
        } else {
          this.setState((prevState) => ({
            rooms: {
              ...prevState.rooms,
              [room]: {
                ...prevState.rooms[room],
                person: response.data.result.id,
                previousUsers: [userId],
              }
            }
          }))
          setTimeout(function () {
            document.getElementById(room).nextElementSibling.classList.toggle('crossed-out')
          }, 1500)
        }
      })
      .catch(err => {
        console.log(err.message);
      })
  }

  componentDidMount() {
    axios
      .get('https://us-central1-clean-wg-muc.cloudfunctions.net/cleaningPlan/home')
      .then((response) => {
        if (Object.keys(response.data).length !== 0) {
          console.log(response.data);
          this.setState(prevState => ({
            rooms: {
              bathroom2f: {
                ...prevState.rooms.bathroom2f,
                person: response.data.bh2f.person.id,
                // personId: response.data.bathroom2f.person.id,
                // previousUsers: ['2'],
              },
              hallway2f: {
                ...prevState.rooms.hallway2f,
                person: response.data.bh2f.person.id,
                // personId: response.data.hallway2f.person.id,
                // previousUsers: ['2'],
              },
              bathroom1f: {
                ...prevState.rooms.bathroom1f,
                person: response.data.bh1f.person.id,
                // personId: response.data.bathroom1f.person.id,
                // previousUsers: ['2'],
              },
              hallway1f: {
                ...prevState.rooms.hallway1f,
                person: response.data.bh1f.person.id,
                // personId: response.data.hallway1f.person.id,
                // previousUsers: ['2'],
              },
              kitchen: {
                ...prevState.rooms.kitchen,
                person: response.data.kitchen.person.id,
                // personId: response.data.kitchen.person.id,
                // previousUsers: ['2'],
              },
              trash: {
                ...prevState.rooms.trash,
                person: response.data.trash.person.id,
                // personId: response.data.trash.person.id,
                // previousUsers: ['2'],
              },
              cellar: {
                ...prevState.rooms.cellar,
                person: response.data.cellar.person.id,
                // personId: response.data.cellar.person.id,
                // previousUsers: ['2'],
              },
              grill: {
                ...prevState.rooms.grill,
                person: response.data.grill.person.id,
                // personId: response.data.grill.person.id,
                // previousUsers: ['2'],
              },
            }
          }))
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.status === 403) {
          this.props.history.push('/login')
        }
        console.log('Probably not logged in.', 'Errormessage:', error.message);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  }

  render() {
    const modal = document.getElementById('confirm-button');
    window.onclick = function(event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    }

    const { loading } = this.state;
    let testvar = 1;
    const test1 = (
      <div>
        <h1>Error 503769492849</h1>
      </div>
    )
    const test = (
      <div className="test-div">
        <div id="confirm-button" className="modal">
          <span  onClick={this.closeModal} className="close"
                title="Close Modal">&times;</span>
          <form className="modal-content">
            <div className="container">
              <h1>Sure?</h1>
              <div className="clearfix">
                <button type="button" className="cancelbtn" onClick={this.closeModal}>Cancel</button>
                <button type="button" className="confirmbtn" onClick={this.handleOptOut}>Yes, Sure</button>
              </div>
            </div>
          </form>
        </div>
        <table>
          <tbody>
          <tr>
            <th>
              <p>Rooms</p>
            </th>
            <th>
              <p>Responsible</p>
            </th>
            <th
              className='availability-cell'
            >
              <p>Not Available</p>
            </th>
          </tr>
          {Object.keys(this.state.rooms).map((room) => {

            //TODO: React re-rendert zu haeufig!
            // console.log('to much re-render');

            let fotze = Object.keys(this.state.button).map(but => {
              return (
                <p className="capitalize">{but}</p>
              )
            })
            let fotze32 = []
            for (const a in this.state.rooms[room].previousUsers) {
              fotze32.push(this.state.rooms[room].previousUsers[a])
            }
            // console.log(fotze32);

            const withoutPrevUser =  (
              <tr
                key={`${room}`}
              >
                <td
                  id={`${room}`}
                >
                  <p>{this.state.rooms[room].roomname + ' ' + this.state.rooms[room].floor1}</p>
                </td>

                <td
                  id={this.state.rooms[room].person}
                  className=''
                >
                  <p className="capitalize">{this.state.rooms[room].person}</p>
                  <p className="capitalize not-responsible">{fotze32}</p>
                </td>
                <td
                  className='availability-cell'
                >
                  <ToggleButton
                    disabled={this.state.button.disabled}
                    color='secondary'
                    className=''
                    id={room + '-button'}
                    value='check'
                    selected={this.state.selected}
                    onClick={this.confirmButtonClick}
                  >
                    <CancelOutlinedIcon
                      fontSize="large"
                      color='#55A340'
                    />
                  </ToggleButton>
                </td>
              </tr>
            )
            const withPrevUser = (
              <div>
                penis
              </div>
            )

            if (this.state.rooms[room].previousUsers) {
              // console.log('prevUser:', this.state.rooms[room].previousUsers);
              const prevUser = Object.getOwnPropertyNames(this.state.rooms[room].previousUsers);
              // console.log('length of prevUser:', prevUser.length); //2
              return withoutPrevUser
            } else {
              return withoutPrevUser
            }
          })}
          </tbody>
        </table>
        {/*<div>*/}
        {/*  <button*/}
        {/*    disabled={loading}*/}
        {/*  >Calculate Week</button>*/}
        {/*</div>*/}
      </div>
    )

    // return test
    if (testvar === 1) {
      return test
    } else {
      return test1
    }
}
}