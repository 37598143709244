export default class Room {
  static properties = {
    roomname: {label: 'roomname'},
    floor1: {label: 'floor'},
    person: {label: 'person'},
  }

  constructor(roomname, floor1, person) {
    this.roomname = roomname;
    this.floor1 = floor1;
    this.person = person;
  }

  // static properties = {
  //   kitchen: {label: 'kitchen', room_name: 'Kitchen'},
  //   hallway1f: {label: 'hallway1f', room_name: 'Hallway 1F'},
  //   bathroom1f: {label: 'bathroom1f', room_name: 'Bathroom 1F'},
  //   hallway2f: {label: 'hallway2f', room_name: 'Hallway 2F'},
  //   bathroom2f: {label: 'bathroom2f', room_name: 'Bathroom 2F'},
  //   cellar: {label: 'cellar', room_name: 'Cellar'},
  //   lawn: {label: 'lawn', room_name: 'Lawn'},
  // }
}