import React from "react";
import { Link } from 'react-router-dom';

import './header.css'
import cleaning_icon_bw from "../media/cleaning_icon_bw.png"
import cleaning_icon_color from "../media/cleaning_icon_color.jpeg"
import userIcon from "../media/user-solid.svg"

export default function CpHeader() {
  return (
    <div>
      <header className="cpHeader">
        <Link to="/special">
          <img id="cleaning-plan-logo" src={cleaning_icon_bw} alt="cleaning-plan-logo" />
        </Link>
        <Link to="/special">
          <img id="user-icon" src={userIcon} alt="user-icon" />
        </Link>
      </header>
    </div>
  );
}
