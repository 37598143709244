import React, { Component } from "react";

import Areas from "./Areas";
import User from "./Users";
import Room from "./Rooms";

export default class CleaningPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //users
      stefan: new User('Stefan', 'Lebküchner', 27, 180),
      pascal: new User('Pascal', 'Erding', 26, 179),
      theo: new User('Theo', 'Kästner', 26, 179),
      quentin: new User('Quentin', 'Leon', 30, 179),
      marco: new User('Marco', 'Löw', 25, 190),

      //rooms
      kitchen: new Room('Kitchen', '1F'),
      bathroom1f: new Room('Bathroom', '1F'),
      hallway1f: new Room('Hallway', '1F'),
      bathroom2f: new Room('Bathroom', '2F'),
      hallway2f: new Room('Hallway', '2F'),
    }
  }
  render() {
    // const { getAllUsers } = require('./getAllUsers')
    // Destructuring Assignment:
    const {
      stefan, pascal, theo, quentin, marco,
    } = this.state
    return (
      <div>
        <h1>{this.props.title}</h1>
        {/*<h1>{getAllUsers[2]}</h1>*/}
        <Areas
          user1={stefan}
          user2={marco}
          user3={theo}
          user4={pascal}
          user5={quentin}
        />
      </div>
    )
  }
}
