import React, { Component } from "react";
import axios from 'axios';
import { authMiddleWare } from '../util/auth'

import CpHeader from "../components/header";
import CleaningPlan from "../components/CleaningPlan";
import "./home.css"


class home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      kitchen: ''
    }
  }

  componentDidMount() {
    // console.log(this.props.history);
    // authMiddleWare(this.props.history);
    // const authToken = localStorage.getItem('AuthToken');
    // axios.defaults.headers.common = { Authorization: `${authToken}` };
    // const rooms = {rooms: 'kitchen'};
    // axios
    //   .get('/home')
    //   .then((response) => {
    //     if (response.data) {
    //       this.setState({
    //         'bathroom2f': response.data.kitchen,
    //         'hallway2f': response.data.kitchen,
    //         'bathroom1f': response.data.kitchen,
    //         'hallway1f': response.data.kitchen,
    //         'kitchen': response.data.kitchen,
    //         'trash': response.data.kitchen,
    //         'cellar': response.data.kitchen,
    //         'grill': response.data.kitchen,
    //       })
    //       console.log(response.data);
    //     }
    //   })
    //   .catch((error) => {
    //     if(error.response.status === 403) {
    //       this.props.history.push('/login')
    //     }
    //     console.log('Probably not logged in.', 'Errormessage:', error.message);
    //     this.setState({ errorMsg: 'Error in retrieving the data' });
    //   });
  }

  render() {
    return (
      <div>
        <CpHeader />
        <div className="homeBody">
          <h1>Cleaning Plan</h1>
          <CleaningPlan />
        </div>
      </div>
    )
  }
}

export default home