export default class User {
  static properties = {
    firstName: {label: 'forname'},
    lastname: {label: 'lastname'},
    age: {label: 'age'},
    height: {label: 'cm'}
  }
  constructor(firstName, lastName, age, height) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.age = age;
    this.height = height;
  }
}
