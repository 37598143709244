import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import home from './pages/home';
import login from './pages/login';
import signup from './pages/signup';
import special from './pages/special'

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/login" component={login}/>
          <Route exact path="/signup" component={signup}/>
          <Route exact path="/" component={home}/>
          <Route exact path="/special" component={special} />
        </Switch>
      </div>
    </Router>
  );
}
export default App;